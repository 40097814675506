import React, { useEffect, useState, createContext } from "react";
import Routes from "./routes";
import { useLocation } from "react-router-dom";
import { useIntercom } from "./utils/useIntercom.hook";
import Helmet from "react-helmet";

export const mobileDeviceContext = createContext(null);

function App() {
  const location = useLocation();
  useIntercom();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  useEffect(() => {
    window.Intercom("boot", {
      app_id: "ljsqaxbc",
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth <= 500);
    });

    return () =>
      window.removeEventListener("resize", () => {
        setIsMobile(window.innerWidth <= 500);
      });
  });

  return (
    <mobileDeviceContext.Provider value={isMobile}>
      <div className="App">
        {/* SEOs */}
        <Helmet>
          <title>Where Brands Meet Podasts | uCast</title>
          <meta name="title" content="Where Brands Meet Podasts | uCast" />
          <meta
            name="description"
            content="uCast is where brands go to find the right podcast to advertise on. Discover and reach your audience with uCast."
          />
          <meta
            content="uCast, ucast, UCast, Ucast, podcast advertizing, monetize my podcast, make money online, podcasting, advertizing, creator monetizaton, Podcast monetizing, how can i make money from my podcast, growth, marketing"
            name="keywords"
          />
          <meta content="3 days" name="revisit-after" />

          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://ucast.xyz/" />
          <meta
            property="og:title"
            content="Where Brands Meet Podasts | uCast"
          />
          <meta
            property="og:description"
            content="uCast is where brands go to find the right podcast to advertise on. Discover and reach your audience with uCast."
          />
          <meta
            property="og:image"
            content="https://ucast.xyz/ucast_banner.png"
          />
          <meta
            property="og:keywords"
            content="uCast, ucast, UCast, Ucast, podcast advertizing, monetize my podcast, make money online, podcasting, advertizing, creator monetizaton, Podcast monetizing, how can i make money from my podcast, growth, marketing"
          />
          <meta property="og:revisit" content="3 days" />

          {/* <!-- Twitter --> */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://ucast.xyz/" />
          <meta
            property="twitter:title"
            content="Where Brands Meet Podasts | uCast"
          />
          <meta
            property="twitter:description"
            content="uCast is where brands go to find the right podcast to advertise on. Discover and reach your audience with uCast."
          />
          <meta
            property="twitter:image"
            content="https://ucast.xyz/ucast_banner.png"
          />
          <meta
            property="twitter:keywords"
            content="uCast, ucast, UCast, Ucast, podcast advertizing, monetize my podcast, make money online, podcasting, advertizing, creator monetizaton, Podcast monetizing, how can i make money from my podcast, growth, marketing"
          />
          <meta property="twitter:revisit" content="3 days" />

          {/* <!-- Google Tag Manager --> */}
          <script>
            {(function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l !== "dataLayer" ? "&l=" + l : "";
              j.async = true;
              j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, "script", "dataLayer", "GTM-KQW4JJ5")}
          </script>
          {/* <!-- End Google Tag Manager --> */}
        </Helmet>

        {/* <TopBar /> */}
        <Routes />
        {/* <Footer /> */}
      </div>
    </mobileDeviceContext.Provider>
  );
}

export default App;
