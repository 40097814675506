import { configureStore } from '@reduxjs/toolkit'

import formSlice from './Form/formSlice';
import userFormSlice from './Form/userForm';

export const store = configureStore({
  reducer: {
    formStep: formSlice,
    userAnswers: userFormSlice,
  },
});