import React from "react";
import analyticsProvider from "../../utils/analyticsProvider";

function EndButtons() {

  const handleBookSignup = () => {
    analyticsProvider.sendEvent("Book Demo");

    window.location.href =
      "https://meetings.hubspot.com/rand-abou-ras/book-a-demo";
  };

  return (
    <div className="flex gap-4">
      <a
        className="ucast-yellow-hollow-gradient-btn"
        href="https://app.ucast.xyz/signup"
      >
        Sign Up Now
      </a>
      <button
        className="ucast-yellow-hollow-gradient-btn"
        onClick={handleBookSignup}
      >
        Book a Demo
      </button>
    </div>
  );
}

export default EndButtons;
