import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/uCastLogo.png";
import { ReactComponent as HandburgerIcon } from "../../assets/icons/handburger.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/cancel.svg";
import "./TopBar.scss";
import analyticsProvider from "../../utils/analyticsProvider";

const TopBar = () => {
  const location = useLocation();
  const [transparent, setTransparent] = useState(false);
  const [showSidenav, setShowSidenav] = useState(false);

  useEffect(() => {
    setShowSidenav(false);
  }, [location]);

  useEffect(() => {
    const handleTransparency = () => {
      if (window.scrollY > 50) {
        setTransparent(true);
      } else {
        setTransparent(false);
      }
    };

    window.addEventListener("scroll", handleTransparency);

    return () => document.removeEventListener("scroll", handleTransparency);
  });

  const onLoginClick = () => {
    analyticsProvider.sendEvent("website::click_login_button");
    window.location.href = "https://app.ucast.xyz/signin";
  };

  const onSignupCick = () => {
    analyticsProvider.sendEvent("website::click_signup_button");
    window.location.href = "https://app.ucast.xyz/signup";
  };

  return (
    <div className={`topbar ${transparent ? "transparent" : ""}`}>
      <div className="flex items-center justify-between max-w-7xl mx-auto w-full">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="ucast logo" width="130px" />
          </Link>
        </div>

        <div>
          <ul className="menu text-white">
            <li>
              <NavLink
                to="/podcaster"
                className="font-semibold text-sm mr-14 hover:text-primary-100"
                activeClassName="text-primary-100"
              >
                For Podcasters
              </NavLink>
            </li>
            <li>
              <button
                className="font-semibold text-sm mr-14 hover:text-primary-100 text-white"
                onClick={onLoginClick}
              >
                Log In
              </button>
            </li>
            <li>
              <button
                className="px-10 py-2 bg-[#FFCB35] rounded-md text-[#1f1f1f] text-sm font-semibold hover:bg-[#FCD563]"
                onClick={onSignupCick}
              >
                Sign Up
              </button>
            </li>
          </ul>
        </div>

        <div className={`overlay ${showSidenav ? "show" : ""}`}>
          <ul className="main pt-12">
            <li className="py-4 inline-block">
              <NavLink
                to="/podcaster"
                className="text-white hover:text-primary-100 text-2xl font-semibold"
              >
                For Podcasters
              </NavLink>
            </li>
            <div className="pt-[1px] w-[80px] mb-4 bg-[#b9b9b9]"></div>
            <li className="py-4">
              <Link
                to={{
                  pathname: "https://app.ucast.xyz/signin",
                }}
                className="text-white hover:text-primary-100 text-2xl font-semibold"
              >
                Log In
              </Link>
            </li>
            <li className="py-4">
              <Link
                to={{
                  pathname: "https://app.ucast.xyz/signup",
                }}
                className="text-white hover:text-primary-100 text-2xl font-semibold"
              >
                Sign Up
              </Link>
            </li>
          </ul>

          {/* Close Button */}
          <button onClick={() => setShowSidenav(false)}>
            <CancelIcon />
          </button>
        </div>

        <div className="button pr-3 pt-2">
          <button onClick={() => setShowSidenav(!showSidenav)}>
            <HandburgerIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
