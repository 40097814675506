const yesForm = [
    {
        step: 1,
        title: "Have you advertised on podcasts before?",
        selections: [
            {
                selectionTitle: "Yes",
                selectionSubtext: ""
            },
            {
                selectionTitle: "No",
                selectionSubtext: ""
            }
        ]
    },
    {
        step: 2,
        title: "What was the business outcome of your last podcast advertising experience?",
        selections: [
            {
                selectionTitle: "Amazing!",
                selectionSubtext: "Increased revenue & growth."
            },
            {
                selectionTitle: "Okay.",
                selectionSubtext: "No positive or negative impact.."
            },
            {
                selectionTitle: "Disappointing.",
                selectionSubtext: "Negative impact on business."
            },
            {
                selectionTitle: "Other"
            },
        ]
    },
    {
        step: 3,
        title: "How can uCast help with your podcast advertising experience?",
        selections: [
            {
                selectionTitle: "Help me explore a diverse pool of  podcast genres.",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Help me partner with multiple podcasts to advertise on.",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Help me reach a larger audience.",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Not sure yet.",
                selectionSubtext: ""
            },
        ]
    },
    {
        step: 4,
        title: "Select the podcasts genres you’d be most interested in promoting your brand.",
        selections: [
            {
                selectionTitle: "Business 📊",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Comedy 🎭",
                selectionSubtext: ""
            },
            {
                selectionTitle: "True Crime 🕵️",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Sports 🏀",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Society & Culture 🫶",
                selectionSubtext: ""
            },
            {
                selectionTitle: "News 🗞️",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Other",
                selectionSubtext: ""
            },
        ]
    },
];

const noForm = [
    {
        step: 1,
        title: "Have you advertised on podcasts before?",
        selections: [
            {
                selectionTitle: "Yes",
                selectionSubtext: ""
            },
            {
                selectionTitle: "No",
                selectionSubtext: ""
            }
        ]
    },
    {
        step: 2,
        title: "I’m interested in...",
        selections: [
            {
                selectionTitle: "Testing a podcast advertising strategy.",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Learning more about podcast advertising.",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Other",
                selectionSubtext: ""
            }
        ]
    },
    {
        step: 3,
        title: "Select the podcasts genres you’d be most interested in promoting your brand.",
        selections: [
            {
                selectionTitle: "Business 📊",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Comedy 🎭",
                selectionSubtext: ""
            },
            {
                selectionTitle: "True Crime 🕵️",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Sports 🏀",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Society & Culture 🫶",
                selectionSubtext: ""
            },
            {
                selectionTitle: "News 🗞️",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Other",
                selectionSubtext: ""
            },
        ]
    },
    {
        step: 4,
        title: "What are you looking to spend on podcast advertising?",
        selections: [
            {
                selectionTitle: "$1,000 - $5,000",
                selectionSubtext: ""
            },
            {
                selectionTitle: "$5,000 - $10,000",
                selectionSubtext: ""
            },
            {
                selectionTitle: "$15,000 - $25,000",
                selectionSubtext: ""
            },
            {
                selectionTitle: "$50,000 +",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Other",
                selectionSubtext: ""
            }
        ]
    },
    {
        step: 5,
        title: "Do you recognize any of these podcasts?",
    },
];

const noFormLearnMore = [
    {
        step: 1,
        title: "Have you advertised on podcasts before?",
        selections: [
            {
                selectionTitle: "Yes",
                selectionSubtext: ""
            },
            {
                selectionTitle: "No",
                selectionSubtext: ""
            }
        ]
    },
    {
        step: 2,
        title: "I’m interested in...",
        selections: [
            {
                selectionTitle: "Testing a podcast advertising strategy.",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Learning more about podcast advertising.",
                selectionSubtext: ""
            },
            {
                selectionTitle: "Other",
                selectionSubtext: ""
            }
        ]
    },
    {
        step: 3,
        title: "Do you recognize any of these podcasts?",
    },
];

export { yesForm, noForm, noFormLearnMore };