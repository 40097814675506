import React from "react";
// import Slider from "infinite-react-carousel";
import DownBadBanner from "../../assets/down-bad.jpeg";
import DropoutsBanner from "../../assets/dropouts.jpeg";
import RaptorsDigestBanner from "../../assets/raptors-digest.jpeg";
import UnsolicitedAdviceBanner from "../../assets/unsolicited-advice.jpeg";
import SalesTransformBanner from "../../assets/sales-transformation.jpeg";
import CTHBanner from "../../assets/comedyTH.avif";
import JumpersJumpBanner from "../../assets/jumpers-jump.avif";

const FeaturedPods = () => {
  const isMobile = window.innerWidth <= 500;
  return (
    <div>
      {isMobile ? (
        // <Slider pauseOnHover slidesToShow={3} arrows={!isMobile} autoplay>
        <>
          <div>
            <div className="podcastCard rounded-2xl">
              <img
                src={DownBadBanner}
                alt="Down Bad Podcast"
                className="inline-block w-full h-full rounded-2xl"
              />
            </div>
          </div>
          <div>
            <div className="podcastCard rounded-2xl">
              <img
                src={SalesTransformBanner}
                alt="Sales Transfromation"
                className="inline-block w-full h-full rounded-2xl"
              />
            </div>
          </div>
          <div>
            <div className="podcastCard rounded-2xl">
              <img
                src={CTHBanner}
                alt="Comedy Trap House"
                className="inline-block w-full h-full rounded-2xl"
              />
            </div>
          </div>
          <div>
            <div className="podcastCard rounded-2xl">
              <img
                src={RaptorsDigestBanner}
                alt="Raptors Digest"
                className="inline-block w-full h-full rounded-2xl"
              />
            </div>
          </div>
          <div>
            <div className="podcastCard rounded-2xl">
              <img
                src={JumpersJumpBanner}
                alt="Jumpers Jump"
                className="inline-block w-full h-full rounded-2xl"
              />
            </div>
          </div>
          <div>
            <div className="podcastCard rounded-2xl">
              <img
                src={DropoutsBanner}
                alt="Dropouts Podcast"
                className="inline-block w-full h-full rounded-2xl"
              />
            </div>
          </div>
          <div>
            <div className="podcastCard rounded-2xl">
              <img
                src={UnsolicitedAdviceBanner}
                alt="Unsolicited Advice"
                className="inline-block w-full h-full rounded-2xl"
              />
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-between">
          <div className="rounded-full border w-[160px]  h-[160px] mt-10">
            <img
              src={DropoutsBanner}
              alt="Dropouts Podcast Banner"
              className="inline-block w-full h-full rounded-full"
            />
          </div>
          <div className="rounded-full border w-[140px]  h-[140px] mt-36">
            <img
              src={SalesTransformBanner}
              alt="Sales Transformation Banner"
              className="inline-block w-full h-full rounded-full"
            />
          </div>
          <div className="rounded-full border w-[130px]  h-[130px]">
            <img
              src={DownBadBanner}
              alt="Down Bad Podcast Banner"
              className="inline-block w-full h-full rounded-full"
            />
          </div>
          <div className="rounded-full border w-[200px] h-[200px] mt-28">
            <img
              src={UnsolicitedAdviceBanner}
              alt="Unsolicited Advice Banner"
              className="inline-block w-full h-full rounded-full"
            />
          </div>
          <div className="rounded-full border w-[130px]  h-[130px]">
            <img
              src={JumpersJumpBanner}
              alt="Jumpers Jump Podcast Banner"
              className="inline-block w-full h-full rounded-full"
            />
          </div>
          <div className="rounded-full border w-[140px]  h-[140px] mt-36">
            <img
              src={RaptorsDigestBanner}
              alt="Raptors Digest Banner"
              className="inline-block w-full h-full rounded-full"
            />
          </div>
          <div className="rounded-full border w-[150px]  h-[150px] mt-10">
            <img
              src={CTHBanner}
              alt="Comedy Trap House Banner"
              className="inline-block w-full h-full rounded-full"
            />
          </div>
          Ï
        </div>
      )}
    </div>
  );
};

export default FeaturedPods;
