import { useEffect } from "react";
import PodHero from "../../assets/pod-hero.png";
import Img2 from "../../assets/pod-img2.png";
import analyticsProvider from "../../utils/analyticsProvider";
import { Helmet } from "react-helmet";
import FeaturedPods from "../../components/FeaturedPods/FeaturedPods";
import "./Podcasters.scss";

const Podcasters = () => {
  useEffect(() => {
    analyticsProvider.sendEvent("website::view_podcasters_page");
  }, []);

  const onSignupCick = () => {
    analyticsProvider.sendEvent("website::click_signup_button");
    window.location.href = "https://app.ucast.xyz/podcaster";
  };

  return (
    <div className="podcastersPage">
      {/* SEOs */}
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>Monetize your podcast with uCast | Podcasters | uCast</title>
        <meta
          property="description"
          content="We help you monetize your podcast regardless of your size and fanbase. We get your podcast ithe right ads that suit your listenership and fanbase."
        />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:url" content="https://ucast.xyz/podcaster/" />
        <meta
          property="og:title"
          content="Monetize your podcast with uCast | Podcasters | uCast"
        />
        <meta
          property="og:description"
          content="We help you monetize your podcast regardless of your size and fanbase. We get your podcast ithe right ads that suit your listenership and fanbase."
        />

        {/* <!-- Twitter --> */}
        <meta property="twitter:url" content="https://ucast.xyz/podcaster/" />
        <meta
          property="twitter:title"
          content="Monetize your podcast with uCast | Podcasters | uCast"
        />
        <meta
          property="twitter:description"
          content="We help you monetize your podcast regardless of your size and fanbase. We get your podcast ithe right ads that suit your listenership and fanbase."
        />

        {/* <!-- Google Tag Manager --> */}
        <script>
          {(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l !== "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "GTM-KQW4JJ5")}
        </script>
        {/* <!-- End Google Tag Manager --> */}
      </Helmet>

      <section className="hero">
        <div className="hero__content px-6">
          <div className="text-white md:max-w-[65%] mt-20 md:mt-0">
            <h1 className="md:text-6xl text-3xl font-bold mb-10 md:leading-[70px]">
              Unlock the true potential of your podcast{" "}
              <span className="text-[#E459B5]">today</span>!
            </h1>
            {/* <p className="text-[#FFFCF2] font-light mb-10 max-w-2xl">
              This is why you would be a podcaster with usLorem ipsum dolor sit
              amet, consectetur adipiscing elit, sed do eiusmod .
            </p> */}
            <button
              onClick={onSignupCick}
              className="px-16 py-3 font-semibold text-sm rounded-lg text-black bg-[#E459B5] hover:bg-[#E376BF]"
            >
              Get Started
            </button>
          </div>
          <div className="flex-1 pt-36 h-full justify-end hidden md:flex">
            <img
              src={PodHero}
              alt="podcast hero"
              className="w-[80%] pointer-events-none inline-block"
            />
          </div>
        </div>
      </section>

      <section className="pb-24 bg-[#1f1f1f] relative">
        <div className="max-w-7xl mx-auto mb-20 text-center md:text-left">
          <h2 className="text-3xl text-white font-bold">
            Get started for free!
          </h2>
        </div>

        <img
          src={Img2}
          alt="podcaster recording"
          className="object-contain md:w-[450px] w-[300px] absolute left-0 bottom-[-125px] md:bottom-[-185px] pointer-events-none"
        />

        <div className="max-w-6xl mx-auto px-4">
          <div className="flex flex-col md:flex-row md:justify-end md:space-x-8">
            <div className="border border-[#3F4244] rounded-tr-xl rounded-bl-xl w-full md:w-[380px] h-[240px] p-10 text-center">
              <h4 className="text-2xl mb-3 text-[#E459B5]">Podcast Networks</h4>
              <p className="text-white text-lg">
                List all your podcasts in minutes! to get started, simply send
                us your rate card to start receiving offers.
              </p>
            </div>

            <div className="border border-[#3F4244] rounded-tr-xl rounded-bl-xl w-full md:w-[380px] min-h-[240px] p-10 text-center mt-14 mb-36 md:mb-0 md:mt-24">
              <h4 className="text-2xl mb-3 text-[#E459B5]">
                Independent Podcasts
              </h4>
              <p className="text-white text-lg">
                To become verified, first list your podcast, then upload proof
                of analytics to showcase the impact of your content.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#121212] py-20 px-6">
        <div className="max-w-6xl mx-auto">
          <div id="steps" className="md:flex justify-between py-16">
            <div className="text-2xl text-white flex space-x-10 md:space-x-4">
              <span className="text-[#E459B5] font-bold inline-block">01</span>{" "}
              <span className="inline-block">Sign up</span>
            </div>

            <div className="text-2xl text-white md:max-w-[200px] flex space-x-10 md:space-x-4 mt-16 mb-16 md:mb-0">
              <span className="text-[#FF7622] font-bold inline-block">02</span>{" "}
              <span className="inline-block">List your podcast</span>
            </div>

            <div className="text-2xl text-white md:max-w-[250px] flex space-x-10 md:space-x-4">
              <span className="text-[#30A280] font-bold inline-block">03</span>
              <span className="inline-block">Receive brand offers</span>
            </div>

            <div className="text-2xl text-white flex space-x-10 md:space-x-4 mt-16 md:mt-24">
              <span className="text-[#C45AC2] font-bold inline-block">04</span>
              <span className="inline-block">Release ads</span>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24 bg-[#1f1f1f]">
        <div className="max-w-6xl mx-auto">
          <h6 className="text-white text-3xl text-center font-semibold mb-24">
            Be one of our featured podcasts
          </h6>

          <FeaturedPods />
        </div>
      </section>
    </div>
  );
};

export default Podcasters;
