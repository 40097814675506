import { useEffect } from "react";
// import Slider from "infinite-react-carousel";
import { ReactComponent as HearIcon } from "../../assets/icons/hear.svg";
import { ReactComponent as OfferIcon } from "../../assets/icons/offer.svg";
import { ReactComponent as GroupsIcon } from "../../assets/icons/groups.svg";
import { ReactComponent as VolumeIcon } from "../../assets/icons/volume.svg";
import { ReactComponent as BulletIcon } from "../../assets/icons/bullet.svg";
import { ReactComponent as ShakeIcon } from "../../assets/icons/handshake.svg";
import { ReactComponent as PinkLine } from "../../assets/icons/pink-line.svg";
import { ReactComponent as OrangeLine } from "../../assets/icons/orange-line.svg";
import { ReactComponent as GreenLine } from "../../assets/icons/green-icon.svg";
import analyticsProvider from "../../utils/analyticsProvider";
import FeaturedPods from "../../components/FeaturedPods/FeaturedPods";
import JavelinLogo from "../../assets/javelin.png";
import YootoozLogo from "../../assets/youtooz.png";
import EmpireLogo from "../../assets/empire.png";
import PodcasterImg from "../../assets/Podcaster.png";

import "./Homepage.scss";

const Homepage = () => {
  const isMobile = window.innerWidth <= 500;

  useEffect(() => {
    analyticsProvider.sendEvent("website::view_home_page");
  }, []);

  const onSignupCick = () => {
    analyticsProvider.sendEvent("website::click_signup_button");
    window.location.href = "https://app.ucast.xyz/signup";
  };

  return (
    <div className="home">
      <div className="hero md:px-28 px-4">
        <div className="max-w-7xl w-full md:mx-auto">
          <div className="md:max-w-2xl">
            <h1 className="md:text-white text-[#E459B5] text-3xl md:text-6xl font-bold mb-4 md:mb-10">
              Make your brand heard
              <HearIcon className="inline" width="30px" />
            </h1>

            <p className="text-white text-lg font-extralight max-w-2xl md:mb-8 mb-10">
              Podcast advertising is a cost-efficient way to build brand
              awareness by reaching niche audiences.
            </p>

            <div className="pt-4">
              <button
                className="inline-block rounded-xl md:py-4 md:px-24 py-3 px-14 bg-primary-100 text-center text-sm text-black font-semibold hover:bg-[#FCD563]"
                onClick={onSignupCick}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="howWorks" className="md:pt-24 md:pb-32 py-20 px-4 bg-[#151515]">
        <div className="max-w-6xl mx-auto mb-10 md:mb-0">
          <h6 className="text-white text-3xl text-center font-semibold md:mb-20 mb-16">
            How it works
          </h6>

          <div className="flex flex-col md:items-start items-center md:flex-row justify-between">
            <div className="w-[250px] h-[140px] md:h-fit bg-[#ECEBEA] rounded-xl md:rounded-md p-4 flex items-start relative">
              <h6 className="font-semibold text-lg text-pink-990 mr-3">01</h6>

              <p className="text-sm">
                Search from our extensive list of verified podcasts and learn
                about the audience of each podcast.
              </p>

              {!isMobile && (
                <PinkLine
                  width="200px"
                  className="absolute bottom-[-140%] right-[-20%]"
                />
              )}
            </div>

            <div className="w-[250px] h-[140px] md:h-fit bg-[#ECEBEA] rounded-xl md:rounded-md p-4 flex items-start md:mt-48 mt-12 relative">
              <h6 className="font-semibold text-lg text-yellow-990 mr-3">02</h6>

              <p className="text-sm">
                Send offers to podcasts that you want representing your brand
                and match with your audience.
              </p>

              {!isMobile && (
                <OrangeLine
                  width="200px"
                  className="absolute top-[-86%] right-[-20%]"
                />
              )}
            </div>

            <div className="relative w-[250px] h-[140px] md:h-fit bg-[#ECEBEA] rounded-xl md:rounded-md p-4 flex items-start md:mt-16 mt-12">
              <h6 className="font-semibold text-lg text-green-990 mr-3">03</h6>

              <p className="text-sm">
                Manage each podcast and campaign, send messages, and plan a
                creative and impactful campaigns.
              </p>

              {!isMobile && (
                <GreenLine
                  width="200px"
                  className="absolute top-[20%] left-[100%] z-0"
                />
              )}
            </div>

            <div className="relative w-[250px] h-[140px] md:h-fit bg-[#ECEBEA] rounded-xl md:rounded-md p-4 flex items-start md:mt-64 mt-12 z-10">
              <h6 className="font-semibold text-lg text-purple-990 mr-4">04</h6>

              <p className="text-sm">
                Sit back, monitor your campaign's progress and share any
                feedback to better connect with the audience.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="py-24 px-4 md:px-0">
        <div className="max-w-6xl mx-auto px-5 md:px-0">
          <h6 className="text-white text-2xl md:text-3xl text-center font-semibold md:mb-24 mb-14">
            See how you can turn their{" "}
            <span className="text-primary-100">listeners</span> into your{" "}
            <span className="text-primary-100">customers</span>
          </h6>

          <div className="flex flex-col md:flex-row items-center justify-start">
            <div className="flex-1 relative">
              <iframe
                width={isMobile ? "100%" : "700"}
                height={isMobile ? "200px" : "450px"}
                src="https://www.youtube.com/embed/d_dy1DGm3Fk"
                title="uCast Product Tour"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="rounded-2xl"
              ></iframe>
            </div>

            <div className="mt-10 md:mt-0 self-center">
              <h6 className="font-semibold text-white text-xl mb-8">
                Campaign Details
              </h6>

              <ul className="mb-14">
                <li className="text-white font-extralight mb-2">
                  <BulletIcon className="inline-block mr-4" />
                  <span className="font-semibold">Brand:</span> EMPIRE Records
                </li>
                <li className="text-white font-extralight mb-2">
                  <BulletIcon className="inline-block mr-4" />
                  <span className="font-semibold">Campaign Name:</span> Tink
                  Campaign
                </li>
                <li className="text-white font-extralight mb-2">
                  <BulletIcon className="inline-block mr-4" />
                  <span className="font-semibold">Reach:</span> 3,000,000
                  listeners
                </li>
                <li className="text-white font-extralight mb-2">
                  <BulletIcon className="inline-block mr-4" />
                  <span className="font-semibold">Campaign Type:</span> 8-min
                  custom segment
                </li>
                <li className="text-white font-extralight mb-2">
                  <BulletIcon className="inline-block mr-4" />
                  <span className="font-semibold">Price:</span> $5,000 total
                  cost
                </li>
              </ul>

              <button
                onClick={onSignupCick}
                className="py-3 px-12 bg-primary-100 text-black text-sm font-semibold rounded-md  hover:bg-[#FCD563]"
              >
                Get Started →
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="py-24 bg-[#151515]">
        <div className="max-w-6xl mx-auto px-5 md:px-0">
          <h6 className="text-white text-3xl text-center font-semibold md:mb-24 mb-16">
            We help grow your brand
          </h6>

          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="flex-1 relative">
              <div className="flex flex-col md:flex-row justify-between md:mb-20">
                <div className=" w-80 mb-8 md:mb-0">
                  <OfferIcon className="inline" width="50px" height="50px" />
                  <h6 className="font-light text-[#FFD96C] text-lg my-2">
                    Low cost
                  </h6>
                  <p className="text-white font-light">
                    Podcast advertising is less expensive, and more efficient
                    than traditional radio or television advertising.
                  </p>
                </div>

                <div className=" w-80 mb-8 md:mb-0">
                  <GroupsIcon className="inline" width="50px" height="50px" />
                  <h6 className="font-light text-[#FFD96C] text-lg my-2">
                    Guaranteed reach
                  </h6>
                  <p className="text-white font-light">
                    Guaranteed reach to your target audience, your ad will be
                    seen by the people interested in your product.
                  </p>
                </div>
              </div>

              <div className="flex flex-col md:flex-row justify-between mb-10">
                <div className=" w-80 8 mb-8 md:mb-0">
                  <VolumeIcon className="inline" width="50px" height="50px" />
                  <h6 className="font-light text-[#FFD96C] text-lg my-2">
                    Impactful listening
                  </h6>
                  <p className="text-white font-light">
                    Podcasts offer a more intimate listening experience than
                    traditional radio or television.
                  </p>
                </div>

                <div className=" w-80 mb-8 md:mb-0">
                  <ShakeIcon className="inline" width="50px" height="50px" />
                  <h6 className="font-light text-[#FFD96C] text-lg my-2">
                    Long-term engagement
                  </h6>
                  <p className="text-white font-light">
                    Podcasts are typically listened to multiple times, your
                    message and brand will be heard repeatedly.
                  </p>
                </div>
              </div>
            </div>

            <div className="hidden md:block">
              <img
                src={PodcasterImg}
                alt="a podcaster"
                width={isMobile ? "100%" : "450px"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="hidden md:block py-10 border-t-2 border-b-2 border-grey-990 ">
        {/* <Slider
          arrows={false}
          autoplay={true}
          pauseOnHover={false}
          slidesToShow={4}
        > */}
        <div className="px-10 border-r-2 border-grey-990">
          <div className="flex">
            <p className="text-grey-990 font-semibold text-sm">Ears Reached</p>
            <h3 className="text-2xl font-bold text-white ml-14"> 2,000,000+</h3>
          </div>
        </div>

        <div className="px-10 border-r-2 border-grey-990">
          <div className="flex">
            <p className="text-grey-990 font-semibold text-sm">
              Campaign deals
            </p>
            <h3 className="text-2xl font-bold text-white ml-14">10,200</h3>
          </div>
        </div>

        <div className="px-10 border-r-2 border-grey-990">
          <div className="flex">
            <p className="text-grey-990 font-semibold text-sm">
              Total Impressions
            </p>
            <h3 className="text-2xl font-bold text-white ml-14">3,000,00+</h3>
          </div>
        </div>

        <div className="px-10 border-r-2 border-grey-990">
          <div className="flex">
            <p className="text-grey-990 font-semibold text-sm">
              Total Podcasts
            </p>
            <h3 className="text-2xl font-bold text-white ml-14">{500}+</h3>
          </div>
        </div>

        <div className="px-10 border-r-2 border-grey-990">
          <div className="flex">
            <p className="text-grey-990 font-semibold text-sm">Ad Spend</p>
            <h3 className="text-2xl font-bold text-white ml-14">$30,000+</h3>
          </div>
        </div>
        {/* </Slider> */}
      </div>

      <div id="podcasts" className="md:py-36 py-16 bg-[#151515]">
        <div className="max-w-6xl mx-auto px-4">
          <h6 className="text-white text-3xl text-center font-semibold mb-24">
            Partner with our featured podcasts
          </h6>

          <FeaturedPods />
        </div>
      </div>

      <div className="md:pt-48 md:pb-24 pt-16 pb-24" id="partners">
        <div className="max-w-6xl mx-auto px-4">
          <h6 className="text-white text-3xl text-center font-semibold mb-24">
            Our trusted partners
          </h6>

          <div className="flex overflow-auto space-x-6 justify-center md:justify-between">
            <img
              src={EmpireLogo}
              alt="Empire Records"
              width={isMobile ? "80px" : "300px"}
            />
            <img
              src={JavelinLogo}
              alt="Javelin Sports"
              width={isMobile ? "80px" : "300px"}
            />
            <img
              src={YootoozLogo}
              alt="Youtooz"
              width={isMobile ? "80px" : "200px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
