import React from "react";
import TopBar from "../TopBar/TopBar";
import Footer from "../Footer/Footer";

const BrandedRoute = ({ children }) => {
  return (
    <>
      <TopBar />
      {children}
      <Footer />
    </>
  );
};

export default BrandedRoute;
