import { createSlice } from '@reduxjs/toolkit'

// initial value(s) for the slice
const initialState = {
  form: {
    step: 1,
    title: "Have you advertised on podcasts before?",
    selections: [
        {
            selectionTitle: "Yes",
            selectionSubtext: "",
            analyticsVal: "yes"
        },
        {
            selectionTitle: "No",
            selectionSubtext: ""
        }
    ]
  }
}

// slice object
const formSlice = createSlice({
    name: 'form slice', // name of slice
    initialState, // setting the initial state in
    reducers: { // different functions that can be called (reducers)

      changeStep: (state, newStep) => {
        state.form = newStep.payload;
      }
    }
  })

  
  export const { changeStep } = formSlice.actions // exporting the reducer functions to be called
  
  export default formSlice.reducer // exporting the variables and values of the slice