import React from 'react';
import { Link } from 'react-router-dom';
import PricePlan from '../../components/PricePlan/PricePlan';
import './Premium.scss';

const Premium = () => {
  return (
    <div className="premiumPage">
      <div className="hero">
        <div className="hero____heroContent">
          <div className="hero__heroContent">
            <p className={'tagline'}>uCast made monitize easy</p>
            <h1>We only get paid if you get paid first</h1>
            <p>
              uCast marketplaces podcasters to adverstisers. Once you’re part of
              uCast client, you’ll have access to many more campaign and
              resouces like the one below. View offer or upgrade today to get
              your ads started.
            </p>
            <div className="flex">
              <Link
                to={{ pathname: 'https://app.ucast.xyz/signup' }}
                target="_blank"
                rel="noreferrer noopener"
                className="ucast-yellow-gradient-btn"
              >
                Get Premium Plan
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="plans">
        <div className="header">
          <h4>uCast Premuim Plan</h4>
          <p>Upgrade with premium clients & resouces</p>
        </div>

        <div className="pricePlans">
          <PricePlan
            title="CPM Campaign"
            description="All the basics for boost your podcst channel. "
          />
          <PricePlan
            title="Premium Package"
            description="All the basics for boost your podcst channel. "
          />
        </div>
      </div>
    </div>
  );
};

export default Premium;
