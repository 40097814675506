import React from "react";

import "./ProgressBar.scss"

function ProgressBar({ totalSteps, currentStep }) {
    const progressPercentage = (currentStep / totalSteps) * 100;

    return (
        <div className="w-1/2 h-1 seperator">
            <div className="fill h-1" style={{"width": `${progressPercentage}%`}}></div>
        </div>
    );
}

export default ProgressBar;