import React from "react";
import Illustration1 from "../../assets/ucast_cpa_illustration.png";
import { ReactComponent as Bullet } from "../../assets/icons/bulletin.svg";
import "./PricePlan.scss";

const PricePlan = ({title, description, data}) => {
  return (
    <div className="pricePlan">
      <div className="pricePlan__header">
        <h6>{title}</h6>
        <p>{description}</p>
        <img src={Illustration1} alt="cpm illustration" />
      </div>
      <div className="pricePlan__body">
        <p>Price & Plans</p>
        <ul>
          <li>
            <Bullet /> Free Plan
          </li>
          <li>
            <Bullet />
            FStandard plan: $15/mo, $12.50/mo yearly
          </li>
          <li>
            <Bullet />
            Pro plan: $49/mo, $37.50mo yearly
          </li>
        </ul>

        <button className="ucast-purple-gradient-btn">Get Free Plan</button>
      </div>
    </div>
  );
};

export default PricePlan;
