import { useContext } from "react";
import { mobileDeviceContext } from "../../App";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import LogoUcast from "../../assets/uCastLogo.png";
import { AiOutlineTwitter } from "react-icons/ai";
import { ImLinkedin2 } from "react-icons/im";
import "./Footer.scss";

const Footer = () => {
  const isMobile = useContext(mobileDeviceContext);

  return (
    <footer className="footer bg-[#040404]">
      <div className="footer__content">
        <div className="footer__content__logo">
          <Link to="/">
            <img src={LogoUcast} alt="logo" width="100px" />
          </Link>
        </div>
        <div className="footer__content__links flex justify-end w-full">
          <div>
            <p className="text-primary-100 font-semibold text-sm mb-4">
              Company
            </p>
            <ul>
              <li>
                <HashLink smooth to="/#howWorks">
                  About
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#podcasts">
                  Featured
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#partners">
                  Trusted By
                </HashLink>
              </li>
            </ul>
          </div>

          <div>
            <p className="text-primary-100 font-semibold text-sm mb-4">Other</p>
            <ul>
              <li>
                <Link to="/podcasters">For Podcasters</Link>
              </li>
              <li>
                <a
                  href="mailto:team@ucast.xyz"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact us
                </a>
              </li>
              <li>
                <a
                  href="mailto:team@ucast.xyz"
                  target="_blank"
                  rel="noreferrer"
                >
                  FAQ
                </a>
              </li>
            </ul>
          </div>

          {!isMobile && (
            <div className="social self-start">
              <a
                href="https://twitter.com/uCastHQ"
                target="_blank"
                rel="noreferrer noopener"
              >
                <AiOutlineTwitter />
              </a>
              <a
                href="https://www.linkedin.com/company/ucastmedia/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <ImLinkedin2 />
              </a>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
