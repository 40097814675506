import { createSlice } from '@reduxjs/toolkit'

// initial value(s) for the slice
const initialState = {
    answer: []
}

// slice object
const userFormSlice = createSlice({
    name: 'user answer slice', // name of slice
    initialState, // setting the initial state in
    reducers: { // different functions that can be called (reducers)
        addAnswer: (state, userAnswer) => {
            state.answer.push(userAnswer);
        }
    }
});

  
  export const { addAnswer } = userFormSlice.actions // exporting the reducer functions to be called
  
  export default userFormSlice.reducer // exporting the variables and values of the slice