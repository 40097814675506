import React from "react";

import "./TitleForm.scss";

function TitleForm({ title }) {
    return (
        <p className="titleForm md:text-2xl text-xl">{title}</p>
    );
}

export default TitleForm;