import { init, track } from "@amplitude/analytics-browser";

class AnalyticsProvider {
  initializa() {
    init("c1f978c49b9da74294abeb987bf31fa1");
  }

  sendEvent(eventName, params) {
    // ReactGA.event(eventName, params);
    track(eventName, params);
  }
}

export default new AnalyticsProvider();
