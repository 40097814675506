import React from "react";

import "./PodcastSelectionForm.scss"

function PodcastSelectionForm({ imageURL }) {
    return (
        <img src={imageURL} alt="Podcast Selection"  className="podcastImage w-1/2 md:w-1/6"/>
    );
}

export default PodcastSelectionForm;