import React from "react";
import { Switch, Route } from "react-router-dom";

//components
import BrandedRoute from "./components/BrandedRoute/BrandedRoute";
import Homepage from "./pages/Homepage/Homepage";
import Podcasters from "./pages/Podcasters/Podcasters";
import Premium from "./pages/Premium/Premium";
import StartForm from "./pages/StartForm/StartForm";

const Routes = () => (
  <>
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <>
            <BrandedRoute>
              <Homepage />
            </BrandedRoute>
          </>
        )}
      />
      <Route
        exact
        path="/podcaster"
        render={() => (
          <>
            <BrandedRoute>
              <Podcasters />
            </BrandedRoute>
          </>
        )}
      />
      <Route
        exact
        path="/premium"
        render={() => (
          <>
            <BrandedRoute>
              <Premium />
            </BrandedRoute>
          </>
        )}
      />
      <Route path="/categories" component={StartForm} />
    </Switch>
  </>
);

export default Routes;
