import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import SelectionForm from "../../components/SelectionForm/SelectionForm";
import TitleForm from "../../components/TitleForm/TitleForm";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import EndButtons from "../../components/EndButtons/EndButtons";
import PodcastSelectionForm from "../../components/PodcastSelectionForm/PodcastSelectionForm";
import { yesForm, noForm, noFormLearnMore } from "../../utils/FormData";
import { useDispatch, useSelector } from "react-redux";
import { addAnswer } from "../../redux/Form/userForm";
import { changeStep } from "../../redux/Form/formSlice";
import Logo from "../../assets/uCastLogo.png";
import "./StartForm.scss";

function StartForm() {
  const formValue = useSelector((state) => state.formStep);
  const userAnswers = useSelector((state) => state.userAnswers);
  const dispatch = useDispatch();

  function changeForm(userAnswers) {
    let newForm = yesForm;

    if (userAnswers.length > 0) {
      if (userAnswers[0].payload.answer === "No") {
        newForm = noForm;

        if (userAnswers.length === 2) {
          if (
            userAnswers[1].payload.answer ===
            "Learning more about podcast advertising."
          ) {
            newForm = noFormLearnMore;
          }
        }
      }
    }

    return newForm;
  }
  const currentForm = changeForm(userAnswers.answer);

  const onSelectFormClick = (value) => {

    // analyticsProvider.sendEvent(value.analyticsKey)
    console.log(value);

    dispatch(
      addAnswer({
        step: formValue.form.step,
        answer: value.selectionTitle,
      })
    );

    if (formValue.form.step < currentForm.length) {
      const newAnswers = userAnswers.answer.map((value) => value);
      newAnswers.push({
        payload: {
          step: formValue.form.step,
          answer: value.selectionTitle,
        },
      });
      const newForm = changeForm(newAnswers);
      const newStep = newForm[formValue.form.step];

      dispatch(changeStep(newStep));
    }
  };

  return (
    <>
      {/* SEOs */}
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>Start Advertizing on Podcasts Today | uCast</title>

        {/* <!-- Google Tag Manager --> */}
        <script>
          {(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l !== "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "GTM-KQW4JJ5")}
        </script>
        {/* <!-- End Google Tag Manager --> */}
      </Helmet>
      <div className="py-5 px-4">
        <div className="max-w-7xl mx-auto">
          <Link to="/">
            <img src={Logo} alt="logo" width="140px" />
          </Link>
        </div>
      </div>

      <div className="startForm flex-col">
        <div className="progress-text mb-5 w-1/2 flex justify-around">
          {currentForm.map((value) => (
            <span>{value.step}</span>
          ))}
        </div>
        <ProgressBar
          totalSteps={currentForm.length}
          currentStep={formValue.form.step}
        />

        <div className="md:mt-8 my-10 md:mb-16 text-center md:text-left px-2">
          <TitleForm title={formValue.form.title} />
        </div>

        {formValue.form.selections ? (
          <div
            className={`my-2 grid md:grid-cols-3 grid-cols-2 gap-4 px-4 ${
              formValue.form.selections.length < 3 && "flex justify-center"
            }`}
          >
            {formValue.form.selections.map((value) => {
              return (
                <SelectionForm
                  text={value.selectionTitle}
                  subText={value.selectionSubtext}
                  onClick={() => onSelectFormClick(value)}
                />
              );
            })}
          </div>
        ) : (
          <div className="gap-4 flex justify-center grid-flow-col mx-3 md:w-9/12 flex-wrap">
            <PodcastSelectionForm imageURL="https://upload.wikimedia.org/wikipedia/en/b/b7/The_Daily_logo.jpg" />
            <PodcastSelectionForm imageURL="https://i.scdn.co/image/ab6765630000ba8a1dd7ccca1c3bfdff71937b47" />
            <PodcastSelectionForm imageURL="https://upload.wikimedia.org/wikipedia/commons/8/8a/Thisamericanlife-wbez.png" />
            <PodcastSelectionForm imageURL="https://i.iheart.com/v3/url/aHR0cHM6Ly93d3cub21ueWNvbnRlbnQuY29tL2QvcGxheWxpc3QvZTczYzk5OGUtNmU2MC00MzJmLTg2MTAtYWUyMTAxNDBjNWIxL2E5MTAxOGE0LWVhNGYtNDEzMC1iZjU1LWFlMjcwMTgwYzMyNy80NDcxMGVjYy0xMGJiLTQ4ZDEtOTNjNy1hZTI3MDE4MGMzM2UvaW1hZ2UuanBnP3Q9MTY0MzA2NjQ3MSZzaXplPUxhcmdl" />
          </div>
        )}

        {formValue.form.step >= currentForm.length && (
          <div className="md:my-20 mt-10">
            <EndButtons />
          </div>
        )}
      </div>
    </>
  );
}

export default StartForm;
