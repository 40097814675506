import React from "react";

import "./SelectionForm.scss"

function SelectionForm({ text, subText, onClick }) {
    return (
        <div className="selectionForm ">
            <button 
                className="rounded md:rounded-lg selection md:px-4 px-1 md:w-64 md:h-24 w-40 h-20"
                onClick={onClick}
            >
                <p className="break-normal text-sm">{text}</p>

                {subText && <p className="subtext">{subText}</p>}

            </button>
        </div>
        
    );
}

export default SelectionForm